import React from 'react';
import { Code, Award, Briefcase, BookOpen } from 'lucide-react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div id="about" className="bg-slate-50 py-16 relative">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-center mb-8 space-x-3">
          <Code className="w-8 h-8 text-indigo-600" />
          <h2 className="text-3xl font-bold text-indigo-900">Expert Background</h2>
        </div>
        
        <Link to="/expertise" className="block">
          {/* <div className="bg-white rounded-lg shadow-sm p-6 border border-slate-200 relative overflow-hidden hover:shadow-md transition-shadow"> */}
          <div className="bg-white rounded-lg shadow-sm p-6 border border-slate-200 relative overflow-hidden transform transition-transform hover:scale-105">  
            <div className="absolute top-0 right-0 w-64 h-64 transform translate-x-32 -translate-y-32">
              <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
            </div>
            
            <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Education & Experience */}
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <Award className="w-6 h-6 text-indigo-600" />
                    <h3 className="text-xl font-semibold text-indigo-900">Education & Background</h3>
                  </div>
                  <div className="bg-slate-50 rounded p-4 border border-slate-100">
                    <ul className="list-none space-y-3">
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                        <span className="text-slate-600">Aerospace Engineering degree from Auburn University</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                        <span className="text-slate-600">Patent holder in AI drone flight control systems</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <Briefcase className="w-6 h-6 text-indigo-600" />
                    <h3 className="text-xl font-semibold text-indigo-900">Professional Experience</h3>
                  </div>
                  <div className="bg-slate-50 rounded p-4 border border-slate-100">
                    <ul className="list-none space-y-3">
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                        <span className="text-slate-600">AI solutions development at Boeing (1989-1996)</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                        <span className="text-slate-600">Successful technical analysis trading (1990s)</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Expertise & Achievements */}
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-center space-x-3">
                    <BookOpen className="w-6 h-6 text-indigo-600" />
                    <h3 className="text-xl font-semibold text-indigo-900">Expertise</h3>
                  </div>
                  <div className="bg-slate-50 rounded p-4 border border-slate-100">
                    <ul className="list-none space-y-3">
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                        <span className="text-slate-600">Artificial Intelligence & Neural Networks</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                        <span className="text-slate-600">Technical Analysis & Market Prediction</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-400 rounded-full mr-2"></span>
                        <span className="text-slate-600">Software Development & System Architecture</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default About;