// import React from 'react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PerformanceDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-6xl mx-auto px-4 py-12">
            <h1 className="text-3xl font-bold text-indigo-900 mb-8">Performance Results</h1>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Summary of Performance</h2>
                <div className="flex flex-col md:flex-row gap-8">
                    
                    {/* Text content */}
                    <div className="md:w-1/3">
                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #1: Sept 16 through Oct 11, 2024.</span>
                        </div>

                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #2: Oct 14 through Nov 8, 2024.</span>
                        </div>

                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #3: Oct 21 through Nov 15, 2024.</span>
                        </div>

                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #4: Oct 28 through Nov 22, 2024.</span>
                        </div>

                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #5: Nov 4 through Nov 29, 2024.</span>
                        </div>

                        <div className="flex mb-4">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Forecast #6: Dec 2 through Dec 27, 2024.</span>
                        </div>

                        {/* Copy all the other forecast divs here */}
                    </div>


                    {/* Image container */}
                    <div className="md:w-2/3">
                        <img src="images/Performance_Summary_1.png" alt="Performance Summary" className="w-full rounded"/>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Sept 16 - Oct 11</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were seven 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Sept 15th date stamp, is here - 
                                <a href="https://ody.sh/IzdeEJIiSj" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 13.2%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_1.png" alt="Performance Results Plot" className="w-full h-auto rounded" />
                    </div>

                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Oct 14 - Nov 8</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were seven 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Oct 13th date stamp, is here - 
                                <a href="https://ody.sh/QiaJDP7aXK" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 5.1%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_2.png" alt="Performance Results Plot" className="w-full h-auto rounded"/>
                    </div>

                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Oct 21 – Nov 15</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were eight 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Oct 20th date stamp, is here - 
                                <a href="https://ody.sh/2VbubKieoy" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 8.3%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_3.png" alt="Performance Results Plot" className="w-full h-auto rounded"/>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Oct 28 – Nov 22</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were eight 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Oct 27th date stamp, is here -  
                                <a href="https://ody.sh/mVGwtqqREL" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 10.2%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_4.png" alt="Performance Results Plot" className="w-full h-auto rounded"/>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Nov 4 – Nov 29</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were eight 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Nov 3 date stamp, is here -  
                                <a href="https://ody.sh/sFR3PXlLx7" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 8.8%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_5.png" alt="Performance Results Plot" className="w-full h-auto rounded"/>
                    </div>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                <h2 className="text-2xl font-bold text-indigo-900 mb-6">Performance Results from Forecast Period Dec 2 – Dec 27</h2>
                
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">There were eight 20-stock-trading-day period forecasts -- each is completely random from the others.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The original forecast post video, with Dec 2 (morning) date stamp, is here -  
                                <a href="https://ody.sh/W5HpDpZqfO" className="text-indigo-600 hover:text-indigo-700">forecast video</a>.
                            </span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Each ROI represents the final value if every stock, in the forecast, was purchased in equal value to each other, and then all stocks sold at the end of the forecast period.</span>
                        </div>
    
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">The mean ROI of all the forecasts was 9.6%.</span>
                        </div>
                    </div>
    
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <img src="images/performance_plot_6.png" alt="Performance Results Plot" className="w-full h-auto rounded"/>
                    </div>
                </div>
            </div>


            {/* Copy all the other performance sections here */}
        </main>
    </div>
  );
};

export default PerformanceDetails;