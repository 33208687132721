import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseDetails_2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (

    <div className="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-6xl mx-auto px-4 py-12">
            <div className="bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Professional Journey & Timeline with Stock Trading</h1>
                
                {/* Timeline Image */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12"> 
                    <img src="images/time_line_5.png" alt="Professional Timeline" className="timeline-img"/>
                </div>

                {/* Stock Trading Block #1-1 */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">Early 90s - Stock Trading with Technical Analysis</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">The internet was just coming of age, so I spent most of my time researching 
                                    stock patterns in the library to verify the claims (to see if patterns still were in play at that time) made by Ted Warren.
                                </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Clark Equipment, shown on the right, was one of a handful of test investments that
                                    I made using the technical analysis methods of Ted Warren. The stock was purchased at $18.75 and sold at $32.125
                                    seven months later, for an ROI of 71.3%.
                                </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">One of my weaknesses was not having patience and typically I sold a stock too soon.  While
                                    profits were made, more often than not, I left a large amount on the table.  As you can see, this includes Clark Equipment.
                                </span>
                            </div>
                
                        </div>
                
                        <div className="bg-slate-50 p-4 rounded-lg h-80">
                            <img src="images/clk.png" alt="Trading Clark Equipment." className="w-full h-full object-contain rounded"/>
                        </div>
                    </div>
                </div>

                {/* Stock Trading Block #1-2 */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1992 - Transactions for Clark Equipment</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                      {/* Left Picture Section  */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/bought_clk.png" alt="Bought Clark Equipment." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Purchase of Clark Equipment shares.</span>
                          </div>
                        </div>
                      </div>
                      {/* Right Picture Section  */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/sold_clk.png" alt="Sold Clark Equipment." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Sale of Clark Equipment shares.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                {/* Stock Trading Block #2-1 */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">Late 90s - Stock Trading with Technical Analysis</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">LSI Logic, shown on the right, was a stock I purchased in late August of 1998 at $13.1875, 
                                    per share, and sold at $27.50 per share, in mid March of 1999. The ROI was 104% over a period of approximately seven months.
                                </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Again - I sold too soon, as there was much more profit to be made.  Keep in mind that
                                    the stock chart on the right represents LSI after a stock split.
                                </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">I had trading loses as well - most of the time those occurred because I experimented 
                                    with new patterns that I had not yet verified, or because I was impatient and sold too soon.  The losses were 
                                    typically small, but they were losses nonetheless.
                                </span>
                            </div>
                
                        </div>
                
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <img src="images/lsi.png" alt="Trading LSI Logic." className="w-full h-auto rounded"/>
                        </div>
                    </div>
                </div>

                {/* Stock Trading Block #2 */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1998 to 1999 - Transactions for LSI Logic</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                      {/* Left Picture Section */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/transaction_1.jpg" alt="Trading LSI Logic." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Purchase of LSI Logic shares.</span>
                          </div>
                        </div>
                      </div>
                      {/* Right Picture Section */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/transaction_2.jpg" alt="Trading LSI Logic." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Sale of LSI Logic shares.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                {/* Stock Trading Summary Block #1  */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">Stock Trading Experience Summary - 1</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                      {/* Left Picture Section  */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/summary_roi.png" alt="Neural Networks." className="w-full h-full object-contain rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Actual ROIs.</span>
                          </div>
                        </div>
                      </div>
                      {/* Right Picture Section */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/summary_roi_patience.png" alt="Ted Warren Book." className="w-full h-full object-contain rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">ROIs if I'd been patient.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                {/* Experience Sections */}
                {/* <!-- <div className="grid md:grid-cols-2 gap-8 mt-12">
                    <div className="bg-slate-50 p-6 rounded-lg">
                        <h2 className="text-2xl font-bold text-indigo-900 mb-4">AI & Technology Innovation</h2>
                        <ul className="space-y-4">
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://onlinelibrary.wiley.com/doi/full/10.1609/aimag.v14i2.1047" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 hover:underline">Pioneered AI solutions at Boeing (1989-1996)</a></span>
                            </li>
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://patents.google.com/patent/US6751529B1/en" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-800 hover:underline">Patented AI drone flight control systems</a></span>
                            </li>
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed AI-driven market prediction software</span>
                            </li>
                        </ul>
                    </div>

                    <div className="bg-slate-50 p-6 rounded-lg">
                        <h2 className="text-2xl font-bold text-indigo-900 mb-4">Market Analysis & Trading</h2>
                        <ul className="space-y-4">
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Successful technical analysis trading (1990s)</span>
                            </li>
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Created high-ROI stock newsletter service</span>
                            </li>
                            <li className="flex items-start">
                                <span className="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed proprietary trading algorithms</span>
                            </li>
                        </ul>
                    </div>
                </div> --> */}
            
            </div>
        </main>

    </div>
  );
};

export default ExpertiseDetails_2;