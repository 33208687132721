import React from 'react';
import { Activity } from 'lucide-react';

const Navbar = () => {
  return (
    <nav className="bg-white shadow-sm relative z-10">
      <div className="max-w-6xl mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Activity className="w-6 h-6 text-indigo-600" />
            <div className="text-xl font-semibold text-indigo-900">
              AI EPPS
            </div>
          </div>
          <div className="flex space-x-4">
            <a href="#performance" className="text-slate-600 hover:text-indigo-700 transition-colors">Performance</a>
            <a href="#technology" className="text-slate-600 hover:text-indigo-700 transition-colors">Technology</a>
            <a href="#about" className="text-slate-600 hover:text-indigo-700 transition-colors">About</a>
            <a href="#contact" className="text-slate-600 hover:text-indigo-700 transition-colors">Contact</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;