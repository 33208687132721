import React from 'react';
import { Mail, Linkedin, ExternalLink } from 'lucide-react';

const Contact = () => {
  return (
    <div id="contact" className="bg-gradient-to-r from-slate-100 to-white py-16">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-center mb-8 space-x-3">
          <Mail className="w-8 h-8 text-indigo-600" />
          <h2 className="text-3xl font-bold text-indigo-900">Contact</h2>
        </div>
        
        <div className="bg-white rounded-lg shadow-sm p-8 border border-slate-200 relative overflow-hidden">
          {/* Decorative Background Element */}
          <div className="absolute top-0 right-0 w-64 h-64 transform translate-x-32 -translate-y-32">
            <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
          </div>

          <div className="relative">
            <div className="max-w-2xl mx-auto text-center mb-8">
              <p className="text-lg text-slate-600">
                For serious inquiries regarding exclusive system acquisition, please reach out through the following professional channels:
              </p>
            </div>

            <div className="max-w-md mx-auto space-y-6">
              {/* Email Contact */}
              <div className="bg-slate-50 rounded-lg p-4 border border-slate-100 transform transition-transform hover:scale-105">
                <div className="flex items-center space-x-3">
                  <Mail className="w-5 h-5 text-indigo-600" />
                  <a 
                    href="mailto:mfouche@aiepps.ai" 
                    className="text-indigo-600 hover:text-indigo-800 flex items-center"
                  >
                    mfouche@aiepps.ai
                    <ExternalLink className="w-4 h-4 ml-2" />
                  </a>
                </div>
              </div>

              {/* LinkedIn Contact */}
              <div className="bg-slate-50 rounded-lg p-4 border border-slate-100 transform transition-transform hover:scale-105">
                <div className="flex items-center space-x-3">
                  <Linkedin className="w-5 h-5 text-indigo-600" />
                  <a 
                    href="https://www.linkedin.com/in/michael-fouche-98702738/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800 flex items-center"
                  >
                    LinkedIn Profile
                    <ExternalLink className="w-4 h-4 ml-2" />
                  </a>
                </div>
              </div>

              {/* Contact Note */}
              <div className="text-center mt-8">
                <p className="text-slate-500 text-sm">
                  Please include relevant background information in your initial contact
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;