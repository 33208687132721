import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseDetails_4 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div classNameName="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-6xl mx-auto px-4 py-12">
            <div className="bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Professional Journey & Timeline</h1>
                
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12"> 
                    <img src="images/time_line_5.png" alt="Professional Timeline" className="timeline-img"/>
                </div>


                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1989 - Introduction to AI and Technical Analysis</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">

                        <div className="space-y-4">
                            <div className="bg-slate-50 p-4 rounded-lg h-64">
                                <img src="images/nnet.png" alt="Neural Networks." className="w-full h-full object-contain rounded"/>
                            </div>

                            <div className="space-y-2 mt-4">
                                <div className="flex items-center">
                                    <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                    <span className="text-slate-600">1989 - Introduced to Neural Networks by Dr. Jim Kim (colleague and friend) at Ford Aerospace (now Maxar Technologies).
                                        <a href="https://www.linkedin.com/in/jim-kim-564a4/" 
                                        className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                        target="_blank"
                                        rel="noopener noreferrer">Dr. Kim on LinkedIn</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="bg-slate-50 p-4 rounded-lg h-64">
                                <img src="images/twarren.png" alt="Ted Warren Book." className="w-full h-full object-contain rounded"/>
                            </div>
                            <div className="space-y-2 mt-4">
                                <div className="flex items-center">
                                    <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                    <span className="text-slate-600">1989 - Introduced to stock trading Technical Analysis by Greg Bossert 
                                            (colleague and friend) at Ford Aerospace (now Maxar Technologies).
                                        <a href="https://www.linkedin.com/in/greg-bossert-4153918/" 
                                        className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                        target="_blank"
                                        rel="noopener noreferrer">Greg Bossert on LinkedIn</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
  
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">Areas of Experience:</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                
                                <span className="text-slate-600">AI development timeline -  
                                    <Link to="/expertise_1" className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">AI Section
                                    </Link>
                                </span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Stock trading with Technical Analysis timeline -  
                                    <Link to="/expertise_2" className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">Stock Trading Section
                                    </Link>
                                </span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">AI EPPS development timeline -  
                                    <Link to="/expertise_3" 
                                       className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">AI EPPS Section
                                    </Link>
                                </span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Technical blog timeline -  
                                    <Link to="/expertise_4" 
                                       className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">Technical Section
                                    </Link>
                                </span>
                            </div>


                        </div>
                    
                    </div>
                </div>
            
            </div>
        </main>
    </div>
  );
};

export default ExpertiseDetails_4;