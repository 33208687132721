import React from 'react';
import { GitBranch, Cpu, Shield } from 'lucide-react';

const Technology = () => {
  return (
    <div id="technology" className="bg-gradient-to-r from-slate-100 to-white py-16 relative">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-center mb-8 space-x-3">
          <GitBranch className="w-8 h-8 text-indigo-600" />
          <h2 className="text-3xl font-bold text-indigo-900">Advanced Technology</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Neural Network Architecture Card */}
          <div className="bg-white rounded-lg p-6 border border-slate-200 relative overflow-hidden transform transition-transform hover:scale-105">
            <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
              <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
            </div>
            <div className="relative">
              <div className="flex items-center mb-4">
                <Cpu className="w-6 h-6 text-indigo-600 mr-2" />
                <h3 className="text-xl font-semibold text-indigo-900">Neural Network Architecture</h3>
              </div>
              <div className="space-y-4">
                <p className="text-slate-600">
                  Sophisticated AI system utilizing Feed-Forward Neural Networks and extensive historical data
                </p>
                <div className="bg-slate-50 rounded p-4 border border-slate-100">
                  <ul className="list-none space-y-2">
                    <li className="flex items-center">
                      <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                      <span className="text-slate-600">Team of 100 specialized Neural Networks</span>
                    </li>
                    <li className="flex items-center">
                      <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                      <span className="text-slate-600">21 years of market data analysis</span>
                    </li>
                    <li className="flex items-center">
                      <span className="w-3 h-3 bg-indigo-400 rounded-full mr-2"></span>
                      <span className="text-slate-600">Advanced pattern recognition</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Exclusive Access Card */}
          <div className="bg-white rounded-lg p-6 border border-slate-200 relative overflow-hidden transform transition-transform hover:scale-105">
            <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
              <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
            </div>
            <div className="relative">
              <div className="flex items-center mb-4">
                <Shield className="w-6 h-6 text-indigo-600 mr-2" />
                <h3 className="text-xl font-semibold text-indigo-900">Exclusive Access</h3>
              </div>
              <p className="text-slate-600 mb-4">
                Single-entity deployment model to maintain market stability and maximize returns
              </p>
              <div className="bg-slate-50 rounded p-4 border border-slate-100">
                <p className="text-slate-600">
                  Controlled deployment prevents market disruption and ensures optimal performance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technology;