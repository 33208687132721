import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PotentialDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-6xl mx-auto px-4 py-12">
            <h1 className="text-3xl font-bold text-indigo-900 mb-8">Potential of Annual Returns</h1>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12">

                <h2 className="text-2xl font-bold text-indigo-900 mb-6">AI EPPS Similarities to Legendary Jim Simons Algorithms in His Words</h2>
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">"There's something called the efficient market theory, which says that there's nothing
                                    in the data, let's say, price data, which will indicate anything about the future because the price 
                                    is always right. The price is always right in some sense, <strong>but that's just not true</strong>."</span>
                        </div>

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">"<strong>There are anomalies in the data, even in the price history data.</strong>"</span>
                        </div>

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">"But gradually, we found more and more and more and more anomalies. None of them is so 
                                    overwhelming that you're going to clean up on a particular anomaly, because if they were, other people 
                                    would have seen them. So they have to be subtle things. <strong>You put together a collection of these subtle 
                                    anomalies, and you begin to get something that will predict pretty well</strong>."</span>
                        </div>

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">"Well, the system, as it is today, is extraordinarily elaborate. ... 
                                            <strong>It's what's called machine learning</strong>."</span>
                        </div>

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">"<strong>You test it out on long-term historical data and price data</strong> and other things. 
                                                Then you add to the system this if it works, and if it doesn't, you throw it out."</span>
                        </div>
                    </div> 

                    <div className="bg-slate-50 p-4 rounded-lg">
                        <video 
                        className="w-full h-auto rounded"
                        controls
                        autoplay
                        muted
                        loop
                        >
                        <source src="videos/Jim_Simons_Interview.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

            </div>
        </main>
    </div>
  );
};

export default PotentialDetails;