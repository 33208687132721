import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div classNameName="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main class="max-w-6xl mx-auto px-4 py-12">
            <div class="bg-white rounded-lg shadow-lg p-8">
                <h1 class="text-3xl font-bold text-gray-900 mb-8">Professional Journey & Timeline</h1>
                
                {/* Timeline Image */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12"> 
                    <img src="images/time_line_5.png" alt="Professional Timeline" class="timeline-img"/>
                </div>


                {/* Technical Blog Block */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">My Technical & Coding Blog</h2>
                    <div class="flex flex-col md:flex-row gap-8">
                    {/* Text content */}
                    <div class="md:w-1/3">
                        <div class="flex mb-4">
                        <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                        <span class="text-slate-600">I started this technical blog five years ago, in 2019. The purpose was write articles on personal projects
                                that I'd done in my spare time at home.  The subject matter was either related to coding (Java, Matlab, etc.) or Neural Network
                                Neural Network applications.
                        </span>
                        </div>
                        <div class="flex">
                        <div class="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                        <span class="text-slate-600">Read the blog:
                            <a href="https://mikescodeprojects.com" class="text-indigo-600 hover:text-indigo-800 hover:underline" target="_blank" rel="noopener noreferrer">Mike's Coding Blog</a>
                        </span>
                        </div>
                    </div>
                    {/* Image container */}
                    <div class="md:w-2/3">
                        <img src="images/blog.png" alt="Mike's Coding Blog" class="w-full rounded"/>
                    </div>
                    </div>
                </div>

                {/* <!-- Experience Sections -->
                <!-- <div class="grid md:grid-cols-2 gap-8 mt-12">
                    <div class="bg-slate-50 p-6 rounded-lg">
                        <h2 class="text-2xl font-bold text-indigo-900 mb-4">AI & Technology Innovation</h2>
                        <ul class="space-y-4">
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://onlinelibrary.wiley.com/doi/full/10.1609/aimag.v14i2.1047" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 hover:underline">Pioneered AI solutions at Boeing (1989-1996)</a></span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://patents.google.com/patent/US6751529B1/en" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 hover:underline">Patented AI drone flight control systems</a></span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed AI-driven market prediction software</span>
                            </li>
                        </ul>
                    </div>

                    <div class="bg-slate-50 p-6 rounded-lg">
                        <h2 class="text-2xl font-bold text-indigo-900 mb-4">Market Analysis & Trading</h2>
                        <ul class="space-y-4">
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Successful technical analysis trading (1990s)</span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Created high-ROI stock newsletter service</span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed proprietary trading algorithms</span>
                            </li>
                        </ul>
                    </div>
                </div> --> */}
            
            </div>
        </main>
    </div>
  );
};

export default ExpertiseDetails;