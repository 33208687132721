import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseDetails_3 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div classNameName="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main class="max-w-6xl mx-auto px-4 py-12">
            <div class="bg-white rounded-lg shadow-lg p-8">
                <h1 class="text-3xl font-bold text-gray-900 mb-8">Professional Journey & Timeline with AI EPPS Development</h1>
                
                {/* Timeline Image */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12"> 
                    <img src="images/time_line_5.png" alt="Professional Timeline" class="timeline-img"/>
                </div>

                {/* AI EPPS Development Block #1 */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">2010 through 2024 - AI EPPS Development</h2>
                
                    <div class="grid md:grid-cols-2 gap-8 mb-8">
                        <div class="space-y-4">
                            <div class="flex">
                                <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span class="text-slate-600">In 2010, I began looking into the components needed for building an AI application that would 
                                    analyze previous stock patterns to predict future performance.  The idea was that Neural Networks could do a much better job
                                    of discerning the more complex patterns.
                                </span>
                            </div>

                            <div class="flex">
                                <div class="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span class="text-slate-600">The components included the stock data source, the Neural Network training data, the Neural 
                                    Network training algorithm, the Neural Network testing data, the Neural Network testing algorithm, and the Neural Network 
                                    prediction algorithm. 
                                </span>
                            </div>

                            <div class="flex">
                                <div class="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span class="text-slate-600">After some initial work in 2010, I published this video -
                                    <a href="https://youtu.be/eWpdu_rqi10"
                                    class="text-indigo-600 hover:text-indigo-800 hover:underline"
                                    target="_blank"
                                    rel="noopener noreferrer">YouTube video from 2010.</a>
                                </span>
                            </div>
                
                       </div>
                
                        <div class="bg-slate-50 p-4 rounded-lg">
                            <img src="images/yt_video.png" alt="YouTube Video" class="w-full h-auto rounded"/>
                        </div>
                    </div>
                </div>

                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">2016 - AI EPPS Development - 1st Newsletter</h2>
                    <div class="flex flex-col md:flex-row gap-8">
                    {/* Text content */}
                    <div class="md:w-1/3">
                        <div class="flex mb-4">
                        <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                        <span class="text-slate-600">This was the first newsletter, published on January 3rd, 2016.
                        </span>
                        </div>
                    </div>
                    {/* Image container */}
                    <div class="md:w-2/3">
                        <img src="images/NWT_Newsletter_1_3_2016.png" alt="Mike's Coding Blog" class="w-full rounded"/>
                    </div>
                    </div>
                </div>

                {/* AI EPPS Development Block #2 */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">AI EPPS Development - Results from 1st Newsletter</h2>
                    <div class="grid md:grid-cols-2 gap-8 mb-8">
                        {/* Left Picture Section */}
                        <div class="space-y-4">
                            <div class="bg-slate-50 p-4 rounded-lg h-120">
                                <img src="images/pub_newsletter_1_1.png" alt="1st Newsletter." class="w-full h-full object-contain rounded"/>
                            </div>
                            <div class="space-y-2 mt-4">
                                <div class="flex items-center">
                                <span class="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                <span class="text-slate-600">This was the result of the forecast - the aggregate ROI was over 100% in 9 months.
                                      The DJIA for that time period was 12.5%.  
                                </span>
                                </div>
                            </div>
                        </div>
                      
                        {/* Right Picture Section */}
                        <div class="space-y-4">
                            <div class="bg-slate-50 p-4 rounded-lg h-120">
                                <img src="images/pub_newsletter_1_2.png" alt="2nd News Letter." class="w-full h-full object-contain rounded"/>
                            </div>

                            <div class="space-y-2 mt-4">

                                <div class="flex items-center">
                                    <span class="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                    <span class="text-slate-600">This was the result of a yearly forecast - the aggregate ROI was almost 120% in 9 
                                            months.  The DJIA for that time period was 2.5%.
                                    </span>
                                </div>
                            
                            </div>

                        </div>
                    </div>
                </div>

                {/* AI EPPS Development Block #3 */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">AI EPPS Development - LinkedIn Post from 2015</h2>
                    <div class="grid md:grid-cols-2 gap-8 mb-8">
                        {/* Left Picture Section */}
                        <div class="space-y-4">
                        <div class="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/linkedin_1_1.png" alt="LinkedIn Post." class="w-full h-full object-contain rounded"/>
                        </div>

                      </div>
                      {/* Right Picture Section */}
                      <div class="space-y-4">
                        <div class="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/linkedin_1_2.png" alt="LinkedIn Post Datestamp." class="w-full h-full object-contain rounded"/>
                        </div>

                        <div class="space-y-2 mt-4">

                            <div class="flex">
                                <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span class="text-slate-600">In this LinkedIn post from 2015, I discussed the results of the newsletter.  This is the  
                                    <a href="https://www.linkedin.com/posts/michael-fouche-98702738_the-concept-behind-this-technology-is-that-activity-6034342799548178432-IWuZ?utm_source=share&utm_medium=member_desktop" 
                                       class="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">LinkedIn Post</a>
                                </span>
                            </div>
    
                          <div class="flex items-center">
                            <span class="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                            <span class="text-slate-600">The above image shows the date and time stamps.</span>
                          </div>

                        </div>

                    </div>
                    </div>
                </div>

                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">2016 - AI EPPS Development - 2nd Newsletter</h2>
                    <div class="flex flex-col md:flex-row gap-8">
                    {/* Text content */}
                    <div class="md:w-1/3">
                        <div class="flex mb-4">
                        <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                        <span class="text-slate-600">This was the first newsletter, published on October 16, 2017.
                        </span>
                        </div>
                    </div>
                    {/* Image container */}
                    <div class="md:w-2/3">
                        <img src="images/NWT_Newsletter_10_16_2017.png" alt="Mike's Coding Blog" class="w-full rounded"/>
                    </div>
                    </div>
                </div>

                {/* AI EPPS Development Block #2 */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">AI EPPS Development - Results from 2nd Newsletter</h2>
                    <div class="grid md:grid-cols-2 gap-8 mb-8">
                        {/* Left Picture Section */}
                        <div class="space-y-4">
                            <div class="bg-slate-50 p-4 rounded-lg h-120">
                                <img src="images/pub_newsletter_2_1.png" alt="1st Newsletter." class="w-full h-full object-contain rounded"/>
                            </div>
                            <div class="space-y-2 mt-4">
                                <div class="flex items-center">
                                <span class="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                <span class="text-slate-600">This was the result of the forecast - the aggregate ROI was almost 35% in 9 months.
                                      The DJIA for that time period was 12.5%.  
                                </span>
                                </div>
                            </div>
                        </div>
                      
                        {/* Right Picture Section */}
                        <div class="space-y-4">
                            <div class="bg-slate-50 p-4 rounded-lg h-120">
                                <img src="images/pub_newsletter_2_2.png" alt="2nd News Letter." class="w-full h-full object-contain rounded"/>
                            </div>

                            <div class="space-y-2 mt-4">

                                <div class="flex items-center">
                                    <span class="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                                    <span class="text-slate-600">This was the result of a yearly forecast - the aggregate ROI was almost 35% in 9 
                                            months.  The DJIA for that time period was 12.5%.
                                    </span>
                                </div>
                            
                            </div>

                        </div>
                    </div>
                </div>

                {/* AI EPPS Development Block #4 */}
                <div class="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 class="text-2xl font-bold text-indigo-900 mb-6">2017 - AI EPPS Development - LinkedIn Post</h2>
                    <div class="grid md:grid-cols-2 gap-8 mb-8">
                      {/* Left Picture Section */}
                      <div class="space-y-4">
                        <div class="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/linkedin_2_1.png" alt="LinkedIn Post." class="w-full h-full object-contain rounded"/>
                        </div>
                      
                      </div>
                      {/* Right Picture Section */}
                      <div class="space-y-4">
                        <div class="bg-slate-50 p-4 rounded-lg h-120">
                          <img src="images/linkedin_2_2.png" alt="LinkedIn Post Datestamp." class="w-full h-full object-contain rounded"/>
                        </div>

                        <div class="space-y-2 mt-4">

                            <div class="flex">
                                <div class="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span class="text-slate-600">In this LinkedIn post, I discussed the results of the newsletter.  This is the 
                                    <a href="https://www.linkedin.com/posts/michael-fouche-98702738_ill-be-releasing-a-newsletter-no-cost-activity-6324406253833506816-uA_E?utm_source=share&utm_medium=member_desktop" 
                                       class="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">LinkedIn Post</a>
                                </span>
                            </div>

                            <div class="flex items-center">
                                <span class="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                                <span class="text-slate-600">The above image shows the date and time stamps.</span>
                            </div>

                        </div>

                    </div>
                    </div>
                </div>

                {/* <!-- Experience Sections -->
                <!-- <div class="grid md:grid-cols-2 gap-8 mt-12">
                    <div class="bg-slate-50 p-6 rounded-lg">
                        <h2 class="text-2xl font-bold text-indigo-900 mb-4">AI & Technology Innovation</h2>
                        <ul class="space-y-4">
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://onlinelibrary.wiley.com/doi/full/10.1609/aimag.v14i2.1047" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 hover:underline">Pioneered AI solutions at Boeing (1989-1996)</a></span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span><a href="https://patents.google.com/patent/US6751529B1/en" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:text-indigo-800 hover:underline">Patented AI drone flight control systems</a></span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed AI-driven market prediction software</span>
                            </li>
                        </ul>
                    </div>

                    <div class="bg-slate-50 p-6 rounded-lg">
                        <h2 class="text-2xl font-bold text-indigo-900 mb-4">Market Analysis & Trading</h2>
                        <ul class="space-y-4">
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-600 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Successful technical analysis trading (1990s)</span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-500 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Created high-ROI stock newsletter service</span>
                            </li>
                            <li class="flex items-start">
                                <span class="h-6 w-6 rounded-full bg-indigo-400 mt-1 mr-3 flex-shrink-0"></span>
                                <span>Developed proprietary trading algorithms</span>
                            </li>
                        </ul>
                    </div>
                </div> --> */}
            
            </div>
        </main>
    </div>
  );
};

export default ExpertiseDetails_3;