import React from 'react';
import { BarChart2, Zap } from 'lucide-react';

const Hero = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-900 to-indigo-800 relative overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute inset-0" style={{
          backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23ffffff' fill-opacity='1'%3E%3Cpath d='M0 0h80v80H0V0zm20 20v40h40V20H20zm20 35a15 15 0 1 1 0-30 15 15 0 0 1 0 30z' opacity='.5'/%3E%3C/g%3E%3C/svg%3E\")",
        }} />
      </div>

      {/* Content */}
      <div className="max-w-6xl mx-auto px-4 py-24 relative">
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <BarChart2 className="w-16 h-16 text-indigo-300" />
          </div>
          <h1 className="text-4xl font-bold text-white mb-4">
            AI EPPS: Artificial Intelligence Equities Performance Prediction System
          </h1>
          <p className="text-xl text-indigo-100 mb-8">
            Transforming Market Predictions with Documented High Returns
          </p>
          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 max-w-2xl mx-auto border border-white/20">
            <div className="flex items-center justify-center space-x-2">
              <Zap className="w-6 h-6 text-yellow-300" />
              <p className="text-lg text-white">
                Achieving 5-10% Monthly ROI through Advanced Neural Network Analysis
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Wave */}
      <div className="absolute bottom-0 w-full">
        <svg viewBox="0 0 1440 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1440 120V0C1200 80 720 120 480 120C240 120 0 80 0 80V120H1440Z" fill="#f8fafc"/>
        </svg>
      </div>
    </div>
  );
};

export default Hero;