import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Performance from './components/Performance';
import Technology from './components/Technology';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

import PerformanceDetails from './components/PerformanceDetails';
import PotentialDetails from './components/PotentialDetails';
import ExpertiseDetails from './components/ExpertiseDetails';

import ExpertiseDetails_1 from './components/ExpertiseDetails_1';
import ExpertiseDetails_2 from './components/ExpertiseDetails_2';
import ExpertiseDetails_3 from './components/ExpertiseDetails_3';
import ExpertiseDetails_4 from './components/ExpertiseDetails_4';

const HomePage = () => (
  <>
    <Hero />
    <Performance />
    <Technology />
    <About />
    <Contact />
  </>
);

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-slate-50 relative">
        <Navbar />
        <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/performance" element={<PerformanceDetails />} />
            <Route path="/potential" element={<PotentialDetails />} />

            <Route path="/expertise" element={<ExpertiseDetails />} /> 
            <Route path="/expertise_1" element={<ExpertiseDetails_1 />} />
            <Route path="/expertise_2" element={<ExpertiseDetails_2 />} />
            <Route path="/expertise_3" element={<ExpertiseDetails_3 />} />
            <Route path="/expertise_4" element={<ExpertiseDetails_4 />} /> 

        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;