import React from 'react';
// import './styles.css';
import { Link } from 'react-router-dom';

import { TrendingUp, ChevronUp, BarChart2 } from 'lucide-react';

const Performance = () => {
  return (
    <div id="performance" className="bg-slate-50 py-16 relative">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-center mb-8 space-x-3">
          <TrendingUp className="w-8 h-8 text-indigo-600" />
          <h2 className="text-3xl font-bold text-indigo-900">Proven Performance</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

          {/* Monthly Returns Card - Now Clickable */}
          <Link to="/performance" className="block">
            <div className="bg-white rounded-lg shadow-sm p-6 border border-slate-200 relative overflow-hidden transform transition-transform hover:scale-105">
              <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
                <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
              </div>
              
              <div className="relative">
                <div className="flex items-center mb-4">
                  <ChevronUp className="w-6 h-6 text-indigo-600 mr-2" />
                  <h3 className="text-xl font-semibold text-indigo-900">Monthly Returns</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-slate-600">
                    Consistent 5-10% monthly ROI across multiple 20-trading-day periods
                  </p>
                  <div className="bg-slate-50 rounded p-4 border border-slate-100">
                    <ul className="list-none space-y-2">
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                        <span className="text-slate-600">September-October 2023: 13.2% mean ROI</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                        <span className="text-slate-600">October-November 2023: 8.3% mean ROI</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-400 rounded-full mr-2"></span>
                        <span className="text-slate-600">Outperforming S&P 500's 10% annual return</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Link>

          {/* Annual Potential Card - Unchanged */}
          {/* <a href="/Potential.html" className="block"> */}
          <Link to="/potential" className="block">
            <div className="bg-white rounded-lg shadow-sm p-6 border border-slate-200 relative overflow-hidden transform transition-transform hover:scale-105">
              <div className="absolute top-0 right-0 w-32 h-32 transform translate-x-16 -translate-y-16">
                <div className="absolute inset-0 bg-indigo-100 opacity-50 rounded-full"></div>
              </div>
              <div className="relative">
                <div className="flex items-center mb-4">
                  <BarChart2 className="w-6 h-6 text-indigo-600 mr-2" />
                  <h3 className="text-xl font-semibold text-indigo-900">Annual Potential</h3>
                </div>
                <div className="space-y-4">
                  <p className="text-slate-600">
                    Compound effects through monthly reinvestment yield remarkable annual returns
                  </p>
                  <div className="bg-slate-50 rounded p-4 border border-slate-100">
                    <ul className="list-none space-y-2">
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-600 rounded-full mr-2"></span>
                        <span className="text-slate-600">Similar to Jim Simons System</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-500 rounded-full mr-2"></span>
                        <span className="text-slate-600">50-100%+ annual ROI potential</span>
                      </li>
                      <li className="flex items-center">
                        <span className="w-3 h-3 bg-indigo-400 rounded-full mr-2"></span>
                        <span className="text-slate-600">Consistent cross-market performance</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Performance;