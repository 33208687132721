import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ExpertiseDetails_1 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (

    <div className="min-h-screen">
        {/* Navigation */}
        <nav className="bg-white shadow-sm border-b border-slate-200">
            <div className="max-w-6xl mx-auto px-4 py-4">
                <Link to="/" className="text-indigo-600 hover:text-indigo-700 inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    Back to Home
                </Link>
            </div>
        </nav>

        {/* Main Content */}
        <main className="max-w-6xl mx-auto px-4 py-12">
            <div className="bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-8">Professional Journey & Timeline with AI</h1>
                
                {/* Timeline Image */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12"> 
                    <img src="images/time_line_5.png" alt="Professional Timeline" className="timeline-img"/>
                </div>

                {/* Boeing AI Work Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1990 to 1996 - AI Research at Boeing</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Started learning to build Neural Network applications from the ground up.  Originally, training was 
                                    done using backpropagation, but within a year, a member of our team, Robert Schorr, implemented a Boeing optimization algorithm. 
                                    As a result, training times were decreased from 1-2 days to several seconds.
                                </span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Developed and successfully tested simulated Neural Network closed-loop guidance 
                                    for aerobraking (reentry) spacecraft and aero-landing. The plot on the right shows the two training profiles 
                                    and the Neural Network generated profiles for Apogee targeting (which was very accurate).</span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Received the Boeing Special Incentive Award for my AI research results.</span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Built up and was the Lead Engineer of Boeing's first Neural-Network focused AI group of engineers.</span>
                            </div>
                
                        </div>
                
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <img src="images/Briefing0007.jpg" alt="Aerocapture Neural Network Training" className="w-full h-auto rounded"/>
                        </div>
                    </div>
                </div>

                {/* Boeing AI Work #2 Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1990 to 1996 - I Research at Boeing</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">The simulation on the right shows the ability of the Neural Network guidance system to modulate the
                                the guidance profile through the atmosphere for different target Apogee altitudes.</span>
                        </div>
                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Developed a methodology for building successful Neural Network applications - 
                                <a href="NNet_App_Dev_Process.docx"
                                className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                target="_blank"
                                rel="noopener noreferrer">Word Document</a>
                            </span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">Other Neural Network applications included weld fault detection, obstacle detection, launch vehicle ascent guidance,
                                    control system testbeds, etc.
                        </span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">This article, by AI Magazine in 1993, includes some of my efforts with Neural Networks - in the article search on "Fouche" - 
                            <a href="https://onlinelibrary.wiley.com/doi/full/10.1609/aimag.v14i2.1047"
                            className="text-indigo-600 hover:text-indigo-800 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer">AI News Article</a>
                        </span>
                        </div>
                    </div>
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <video 
                        className="w-full h-auto rounded"
                        controls
                        autoplay
                        muted
                        loop
                        >
                        <source src="videos/NNet_Aerocapture.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    </div>
                </div>

                {/* Cart with Inverted Pendulum Control Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1998 - Neural Control of 2-DOF System</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">

                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">Description of Cart-Pend technology ....</span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">Description of Cart-Pend technology ....</span>
                        </div>
                    </div>
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <video 
                        className="w-full h-auto rounded"
                        controls
                        autoplay
                        muted
                        loop
                        >
                        <source src="videos/Cart_Pend_Demo.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    </div>
                </div>

                {/* Test Stand Flights in Garage Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">1999 - Learning to Build Neural Controlled Flight in my Garage</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Using my own funds and time (evenings, weekends, time off from my day job), I assembled the
                                prototype AI (Neural Network) flight control system, integrated with a drone in my garage. The drone was as a testbed 
                                for my Neural Network flight control algorithms.  More details can be found in my blog article -
                                <a href="https://mikescodeprojects.com/2020/01/05/ai-helicopter-control/"
                                className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                target="_blank"
                                rel="noopener noreferrer">A Lesson in Perseverance: Development of a Prototype AI Neural Network Helicopter Control System</a>
                            </span>
                        </div>

                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">The video on the right shows the first successful flight of the drone with the AI Neural Network 
                                flight control system, operating from a laptop that was tethered to the drone. For me, it felt like the Wright Brothers'
                                first flight.</span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">In this prototype, Neural Networks were stabilizing the roll and pitch axes at a frequency of 20 Hz (20 
                            times per second or every 50 milliseconds). The Neural Networks were trained using a Levenberg-Marquardt optimization algorithm.
                        </span>
                        </div>
                    </div>
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <video 
                        className="w-full h-auto rounded"
                        controls
                        autoplay
                        muted
                        loop
                        >
                        <source src="videos/Test_Stand.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    </div>
                </div>

                {/* Built AI Drone Prototype in Garage Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">2001 - Built AI Flight Control Drone Prototype in my Garage</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    <div className="space-y-4">

                        <div className="flex">
                            <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                            <span className="text-slate-600">Using my own funds and time (evenings, weekends, time off from my day job), I assembled the
                                prototype AI (Neural Network) flight control system, integrated with a drone in my garage. The drone was as a testbed 
                                for my Neural Network flight control algorithms.  More details can be found in my blog article -
                                <a href="https://mikescodeprojects.com/2020/01/05/ai-helicopter-control/"
                                className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                target="_blank"
                                rel="noopener noreferrer">A Lesson in Perseverance: Development of a Prototype AI Neural Network Helicopter Control System</a>
                            </span>
                        </div>

                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">The video on the right shows the first successful flight of the drone with the AI Neural Network 
                                flight control system, operating from a laptop that was tethered to the drone. For me, it felt like the Wright Brothers'
                                first flight.</span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">In this prototype, Neural Networks were stabilizing the roll and pitch axes at a frequency of 20 Hz (20 
                            times per second or every 50 milliseconds). The Neural Networks were trained using a Levenberg-Marquardt optimization algorithm.
                        </span>
                        </div>
                    </div>
                    <div className="bg-slate-50 p-4 rounded-lg">
                        <video 
                        className="w-full h-auto rounded"
                        controls
                        autoplay
                        muted
                        loop
                        >
                        <source src="videos/Backyard_Success.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    </div>
                </div>

                {/* NRI AI Drone Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">2002 through 2009 - AI Neural Network Controlled Drones - Neural Robotics, Inc. (NRI)</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">In 2002, I founded Neural Robotics, Inc., hereon referred to as NRI.</span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">The flight control algorithm was patented in 2004 -
                                    <a href="https://patents.google.com/patent/US6751529B1/en"
                                    className="text-indigo-600 hover:text-indigo-800 hover:underline"
                                    target="_blank"
                                    rel="noopener noreferrer">AI Patent</a>
                                </span>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">The NRI product line consisted of the three drone helicopters shown on the right.
                                    From left to right, the Express-G (gas powerplant), the Explorer (2-stage turbine powerplant), and the
                                    Express-E (Lithium-Ion electric powerplant). </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Defense Review published an article on our weaponization efforts with the largest AI drone,
                                    the Explorer. The article mentions me, my role at NRI, and discusses our efforts with weaponization - 
                                    <a href="https://defensereview.com/hyper-lethal-mini-robotic-attack-helicopter-with-turbine-engine-debuts/" 
                                       className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">Defense Review Article</a>
                                </span>
                            </div>
                        </div>
                
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <img src="images/NRI_drones.jpg" alt="NRI Drones" className="w-full h-auto rounded"/>
                        </div>
                    </div>
                </div>

                {/* Andy Rubin Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">Google Purchased AI Drone from NRI</h2>
                
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                        <div className="space-y-4">
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Andy Rubin, then head of Google's robotics efforts and creator of the Android mobile 
                                    operating system, purchased one of our AI-controlled helicopter drones.</span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-400 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">The drone utilized multiple neural networks working in concert to achieve autonomous 
                                        flight control.
                                </span>
                            </div>
                
                            <div className="flex">
                                <div className="flex-shrink-0 w-2 h-2 bg-indigo-300 rounded-full mr-3 mt-2"></div>
                                <span className="text-slate-600">Read the full story in the 
                                    <a href="https://www.nytimes.com/2013/12/04/technology/google-puts-money-on-robots-using-the-man-behind-android.html" 
                                       className="text-indigo-600 hover:text-indigo-800 hover:underline" 
                                       target="_blank" 
                                       rel="noopener noreferrer">New York Times article</a>
                                </span>
                            </div>
                        </div>
                
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <img src="images/nyt-rubin-article.png" alt="New York Times article featuring Andy Rubin" className="w-full h-auto rounded"/>
                        </div>
                    </div>
                </div>
                
                {/* Flight Test Videos Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">AI-Controlled Drone Flight Tests</h2>
                    
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    {/* Left Video Section */}
                    <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                        <video controls className="w-full h-auto rounded">
                            <source src="videos/Drone_Test_Flight.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <div className="space-y-2 mt-4">
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Flight testing of a prototype electric AI-controlled drone</span>
                            </div>
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">
                                View on YouTube: 
                                <a href="https://youtu.be/vt5q8vfk_gw" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-700">
                                    Original video from Oct 27, 2006
                                </a>
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                
                    {/* Right Video Section */}
                    <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <video 
                            className="w-full h-auto rounded"
                            controls
                            autoplay
                            muted
                            loop
                            >
                            <source src="videos/Two_Drones_in_Air.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                            </video>
    
                        <div className="space-y-2 mt-4">
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Hover flight test of the Google AI drone (along with another ) before delivery.</span>
                            </div>
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">
                                View on YouTube: 
                                <a href="https://youtu.be/cl1zVeeW2AA" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-700">
                                Original video from Jan 3, 2007
                                </a>
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/* Neural Network Tutorial Videos Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">2018 - Feed-Forward Neural Network Tutorial Videos</h2>
                    
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                    // Left Video Section 
                    <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                        <video controls className="w-full h-auto rounded">
                            <source src="videos/NNet_Tutorial_1.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <div className="space-y-2 mt-4">
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">The first of three tutorial videos.</span>
                            </div>
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">
                                View on YouTube: 
                                <a href="https://youtu.be/dnazn9xnTCA" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-700">
                                    Original video from Jan 22, 2018
                                </a>
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                
                    {/* Right Video Section no "autoplay" */}
                    <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                            <video 
                            className="w-full h-auto rounded"
                            controls
                            muted
                            loop
                            >
                            <source src="videos/NNet_Tutorial_2.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                            </video>
    
                        <div className="space-y-2 mt-4">
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">The second of three tutorial videos.</span>
                            </div>
                            <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">
                                View on YouTube: 
                                <a href="https://youtu.be/Sis-xTcP76w" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-700">
                                Original video from Jan 22, 2018
                                </a>
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                {/* NNet Traffic Light Effort */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">2022 - Traffic Light Signal Recognition by Neural Networks</h2>
                    <div className="grid md:grid-cols-2 gap-8 mb-8">
                      {/* Left Picture Section */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/nnet_traffic_light_1.webp" alt="Training Set." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Training set ...</span>
                          </div>
                        </div>
                      </div>
                      {/* Right Picture Section */}
                      <div className="space-y-4">
                        <div className="bg-slate-50 p-4 rounded-lg">
                          <img src="images/nnet_traffic_light_2.jpg" alt="Neural Network Results." className="w-full h-auto rounded"/>
                        </div>
                        <div className="space-y-2 mt-4">
                          <div className="flex items-center">
                            <span className="w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></span>
                            <span className="text-slate-600">Results ...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                {/* Technical Blog Block */}
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-2xl font-bold text-indigo-900 mb-6">My Technical & Coding Blog</h2>
                    <div className="flex flex-col md:flex-row gap-8">
                    {/* Text content */}
                    <div className="md:w-1/3">
                        <div className="flex mb-4">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-600 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">I started this technical blog five years ago, in 2019. The purpose was write articles on personal projects
                                that I'd done in my spare time at home.  The subject matter was either related to coding (Java, Matlab, etc.) or Neural Network
                                Neural Network applications.
                        </span>
                        </div>
                        <div className="flex">
                        <div className="flex-shrink-0 w-2 h-2 bg-indigo-500 rounded-full mr-3 mt-2"></div>
                        <span className="text-slate-600">Read the blog:
                            <a href="https://mikescodeprojects.com" className="text-indigo-600 hover:text-indigo-800 hover:underline" target="_blank" rel="noopener noreferrer">Mike's Coding Blog</a>
                        </span>
                        </div>
                    </div>
                    {/* Image container */}
                    <div className="md:w-2/3">
                        <img src="images/blog.png" alt="Mike's Coding Blog" className="w-full rounded"/>
                    </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
  );
};

export default ExpertiseDetails_1;