import React from 'react';
import { Activity } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-slate-50 border-t border-slate-200">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-col items-center space-y-4">
          {/* Logo */}
          <div className="flex items-center space-x-2">
            <Activity className="w-5 h-5 text-indigo-600" />
            <span className="text-lg font-semibold text-indigo-900">AI EPPS</span>
          </div>

          {/* Copyright */}
          <div className="text-center">
            <p className="text-slate-600">
              © {new Date().getFullYear()} AI EPPS. All rights reserved.
            </p>
            <p className="text-slate-500 text-sm mt-1">
              Artificial Intelligence Equities Performance Prediction System
            </p>
          </div>

          {/* Decorative Line */}
          <div className="w-24 h-1 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;